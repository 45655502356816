.profileimage:hover {
  transition: transform 0.5s, filter 1.5s ease-in-out !important;
  filter: grayscale(100%) !important;
  transform: scale(1.1);
  border-radius: "20px";
}
.filterbutton:hover {
  box-shadow: 0px 0px 3px rgb(143, 143, 143);
}

.filters #size-small-standard-multi:before,
.filters #size-small-standard-multi:after,
.css-11tfndm::before,
.css-11tfndm::after {
  border-bottom: none !important;
}
.filters #size-small-standard-multi-label {
  color: #00c0d9;
}

.filters #size-small-standard-multi,
.filters #size-small-standard-multi-label {
  left: 0 !important;
  right: 0 !important;
  transform-origin: top center !important;
  top: 1px !important;
}
.filters #size-small-standard-multi,
.filters #size-small-standard-multi-label {
  text-align: center;
}
.filters #size-small-standard-multi {
  padding: 4px 20px 13px !important;
}
.filters
  .css-1o3j6gt-MuiAutocomplete-root
  .MuiInput-root.MuiInputBase-sizeSmall
  .MuiInput-input {
  padding: 2px 4px 3px 20px;
}
.filters .css-1q60rmi-MuiAutocomplete-endAdornment {
  right: 12px !important;
  top: calc(50% - 20px) !important;
}
@media (min-width: 769px) {
  .footerlogo {
    height: 80px;
  }
}
@media (max-width: 899px) {
}
@media (max-width: 768px) {
  .css-1hrexks-MuiGrid-root {
    max-width: 100% !important;
  }

  .steppers span {
    font-size: 0.575rem;
  }

  .footerlogo {
    height: 50px;
  }
}

.fileContainer .uploadPictureContainer img.uploadPicture {
  width: 200px !important;
  height: 200px !important;
}
.fileContainer .uploadPictureContainer {
  width: 200px !important;
  height: 200px !important;
  margin: 1% !important;
  padding: 0px !important;
}
