div.ActiveUser {
  background-color: rgba(0, 0, 0, 0.1);
}

.css-13dyovk-MuiButtonBase-root-MuiButton-root {
  background-color: #00c0d9 !important;
}
.css-1q8v7ak-MuiSvgIcon-root {
  color: #00c0d9 !important;
}

body,
html,
#root {
  height: 100%;
}
